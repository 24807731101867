import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import OpenGraph from '../assets/OpenGraph.png'

export const SEO = ({title, keywords, description, image, url, enableSmartBanner = true}) => {
    const { site } = useStaticQuery(
        graphql`
            query MyQuery {
                site {
                    siteMetadata {
                        title
                        description
                        keywords
                        author
                        siteUrl
                    }
                }
            }
        `
    );

    const metaTitle = title || site.siteMetadata.title
    const metaDescription = description || site.siteMetadata.description;
    const metaKeywords = keywords || site.siteMetadata.keywords;
    const metaUrl = url || site.siteMetadata.siteUrl;

    const ogImage = image || OpenGraph

    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
        >
            <title>{metaTitle}</title>
            <meta name="p:domain_verify" content="ae2dd6060914f80175af30034016eb6c"/>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords.join(',')}></meta>
            <meta property="og:title" content={metaTitle} />
            <meta property="og:url" content={metaUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={ogImage} />
            {enableSmartBanner && <meta name="apple-itunes-app" content="app-id=1538317859, app-clip-bundle-id=com.mintuz.Workout-App.Clip"/>}
        </Helmet>
    );
}
